import React, { useEffect, useState } from "react";
import vulpesLogo from "./logo_vulpes.png";
import hubcapLogo from "./logo_hubcap.png";
import dynabackLogo from "./logo_dynaback.png"
import intLogo from "./logo_int.png"
import { Box, Container, Grid, MenuItem, Select, Button ,InputLabel, FormControl, Modal, Typography} from "@mui/material";
// import STLViewer from 'stl-viewer'
import { v4 as uuid } from 'uuid';
import { StlViewer } from 'react-stl-file-viewer'


function App() {

  const modalStyle = {
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    width: '80%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const assetTypes = [
    [
      "Battery",
      [
        ["Length (mm)", [20, 25, 30, 35, 40]],
        ["Width (mm)", [5, 10, 15]],
      ],
    ],
    [
      "Box",
      [
        ["Width (mm)", [35, 40, 45, 50]],
        ["Length (mm)", [50, 55, 60, 65, 70, 75, 80]],
        ["Height (mm)", [30]],
        ["No. of buttons", [4, 5, 6, 8]],
      ],
    ],
    [
      "Box Holder",
      [
        ["Width (mm)", [30, 35, 40, 45, 50]],
        ["Length (mm)", [30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80]],
        ["Height (mm)", [30]],
      ],
    ],
    ["Controller", [["Diameter (mm)", [15, 17, 19, 21, 23, 25]]]],
    ["Electrodes", [["Diameter (mm)", [5, 10, 15, 20, 25]]]],
    [
      "Heating Pad",
      [
        ["Length (mm)", [40, 50, 60, 70, 80, 90, 100]],
        ["Width (mm)", [40, 50, 60, 70, 80, 90, 100]],
      ],
    ],
    [
      "Lighting",
      [
        ["Tube Diameter (mm)", [1, 2, 3, 4, 5]],
        [
          "Length (mm)",
          [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        ],
      ],
    ],
    [
      "Male Connector",
      [
        ["Connector Type", ["USB-A", "USB-B", "USB-C", "DC"]],
        [
          "Cable Length (mm)",
          [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
        ],
      ],
    ],
    [
      "Silicone Holder",
      [
        ["Width (mm)", [30, 35, 40, 45, 50]],
        ["Length (mm)", [40, 45, 50, 55, 60, 65, 70, 75, 80]],
      ],
    ],
    [
      "Simple Box",
      [
        ["Length (mm)", [30, 35, 40, 45, 50]],
        ["Width (mm)", [40, 45, 50, 55, 60, 65, 70, 75, 80]],
        ["Thickness (mm)", [20, 25, 30]],
      ],
    ],
    [
      "Snake Cables",
      [
        ["P1", [2, 3, 4, 5, 6]],
        ["P2", [2, 3, 4, 5, 6]],
        [
          "No. of waves",
          [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        ],
        ["Radius (mm)", [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]],
      ],
    ],
  ];

  const kWidth = "Width (mm)";
  const kLength = "Length (mm)";
  const kThickness = "Thickness (mm)";
  const kNoWaves = "No. of waves";
  const kHeight = "Height (mm)";
  const kNoButtons = "No. of buttons";
  const kDiam = "Diameter (mm)";
  const kTDiam = "Tube Diameter (mm)";
  const kConnType = "Connector Type";
  const kCabLen = "Cable Length (mm)";
  const kP1 = "P1";
  const kP2 = "P2";
  const kRad = "Radius (mm)";

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const customParamsChangeHandler = (event) => {

    setIsPreviewing(false);

    customParams[event.target.name] = event.target.value
    if(assetTypes.find((anAsset) => anAsset[0] === selectedAssetType)[1].length == Object.keys(customParams).length) {
      getAssetPath();
      
    }
  };

  const timeout=(delay)=>{
    return new Promise( res => setTimeout(res, delay) );
}


  const [volume, setvolume] = useState(0)


  const getAssetPath = () => {
    var assetName = "";
    switch(selectedAssetType) {
      case "Battery":
        assetName = "/assets/battery/Box_" + customParams[kLength] + "XY_" + customParams[kWidth] + "_Z_";
        break;
      case "Box":
        assetName = "/assets/box/BOX_X_" + customParams[kWidth] + "_Y_" + customParams[kLength] + "_Z_" + customParams[kHeight] + "_BUTTONS_" + customParams[kNoButtons];
        break;
      case "Box Holder":
        assetName = "/assets/box_holder/BOX_X_" + customParams[kWidth] + "_Y_" + customParams[kLength] + "_Z_" + customParams[kHeight];
        break;
      case "Controller":
        assetName = "/assets/controller/Controller_R_" + customParams[kDiam];
        break;
      case "Electrodes":
        assetName = "/assets/electrodes/Electrodes" + customParams[kDiam] + "_radius_";
        break;
      case "Heating Pad":
        assetName = "/assets/heating_pad/hp_x_" + customParams[kWidth] + "_y_" + customParams[kLength];
        break;
      case "Lighting":
        assetName = "/assets/lighting/ligthing_width_" + customParams[kTDiam] + "_length_" + customParams[kLength];
        break;
      case "Male Connector":
        assetName = "/assets/male_connector/Maleconnector" + customParams[kConnType] + "_length_" + customParams[kCabLen];
        break;
      case "Silicone Holder":
        assetName = "/assets/silicone_holder/BOX_HOLDER_X_" + customParams[kWidth] + "_Y_" + customParams[kLength];
        break;
      case "Simple Box":
        assetName = "/assets/simple_box/BOX_X_" + customParams[kLength] + "_Y_" + customParams[kWidth] + "_Z_" + customParams[kThickness];
        break;
      case "Snake Cables":
        assetName = "/assets/snake_cables/SNAKE_P1_" + customParams[kP1] + "_P2_" + customParams[kP2] + "_Radius_" + customParams[kRad] + "_Waves_" + customParams[kNoWaves];
        break;
      default:
        {}
    }
    setIsPreviewing(assetName);
    console.log("lololololol:")
    console.log(assetName)
  };

  const onOpen=()=>{
    setPrevAssetType(selectedAssetType)
    setSelectedAssetType(false)
    setIsPreviewing(false)
  }



  const assetChangeHandler = (event) => {
    setSelectedAssetType(event.target.value);
    setCustomParams({});
    setIsPreviewing(false);
    setSelectedFileFormat(false)
  };

  const onAssetSelectClose = (event) => {
    // if (checkOnAssetCloseCond && prevAssetType) {
    //   setSelectedAssetType(prevAssetType)
    //   setPrevAssetType(false)
    // }
  };

  const fileFormatSelectChangeHandler = (event) => {
    setSelectedFileFormat(event.target.value);
  }

  const [selectedAssetType, setSelectedAssetType] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [customParams, setCustomParams] = useState({});
  const [prevAssetType, setPrevAssetType] = useState(false);
  const [selectedFileFormat, setSelectedFileFormat] = useState(false);

  const url =
    "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl";

  

  const style = {
    // top: 0,
    // left: 0,
    // width: "100px",
    // height: "420px",
    // paddingTop:"20px"
  };

  return (
    <div className="App w-full min-h-screen">
      <div className="flex flex-col w-full min-h-screen gap-8">
        <header className="w-full flex h-32 items-end justify-center">
          <h1 className="text-black font-playfair text-5xl app-name">
            {" "}
            SMARTEXMOD{" "}
          </h1>
        </header>
        <main className="text-black w-full grow flex-col flex items-center gap-12">
          <section className="section-text font-poppins text-2xl text-left w-4/6 mt-4 leading-9">
            SMARTEXMOD is an efficiency tool providing pre-build assets for
            design, development and prototyping of smart clothing items. Please
            select the asset and the format for the download.
            <p className="md:w-5/6 text-left h-22 text-sm w-full md:text-base pt-10">
              Please note that the .dxf can be used for design software, and
              .obj and .mtl for rapid prototyping
            </p>
          </section>

          <Container>
            <Grid container spacing={2} padding={0}>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <Box className="customBox">
                  <label
                    className="text-black text-2xl font-bold font-playfair mt-4 custom-box-label"
                    htmlFor="assets"
                  >
                    1. Select an asset
                  </label>


                  <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-label">Asset Type</InputLabel>
                  <Select
                  variant='outlined'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{
                      width: "100%",
                      marginY: 1,
                      marginX: -2
                    }}
                    label='Asset Type'
                    onChange={assetChangeHandler}
                    // onClose={onAssetSelectClose}
                    onOpen={onOpen}
                  >
                    {assetTypes.map((anAsset) => (
                      <MenuItem value={anAsset[0]}>{anAsset[0]}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item lg={4} md={12} sm={12} xs={12}>
                <Box className="customBox">
                  <label
                    className="text-black text-2xl font-bold font-playfair mt-4 custom-box-label"
                    htmlFor="assets"
                  >
                    {" "}
                    2. Select custom parameters{" "}
                  </label>
                  {!selectedAssetType ? (
                    <label
                      className="text-black text-s font-italic font-playfair mt-4"
                      htmlFor="assets"
                    >
                      {" "}
                      First select an asset type{" "}
                    </label>
                  ) : (
                    assetTypes
                      .find((anAsset) => anAsset[0] === selectedAssetType)[1]
                      .map((aCustomParam) => (
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id={uuid()}>{aCustomParam[0]}</InputLabel>
                          <Select
                            variant='outlined'
                            sx={{
                              width: "100%",
                              marginY: 1,
                              marginX: -2
                            }}
                            label={aCustomParam[0]}
                            name={aCustomParam[0]}
                            onChange={customParamsChangeHandler}
                            // onOpen={()=>{
                            //   setIsPreviewing(false)
                            // }}
                          >
                            {aCustomParam[1].map((val) => (
                              <MenuItem value={val}>{val}</MenuItem>
                            ))}
                          </Select>
                          </FormControl>
                      ))
                  )}
                </Box>
              </Grid>

              <Grid item lg={4} md={12} sm={12} xs={12}>
                <Box className="customBox">
                  <label
                    className="text-black text-2xl font-bold font-playfair mt-4 custom-box-label"
                    htmlFor="assets"
                  >
                    {" "}
                    3. Preview and download{" "}
                  </label>

                  {!isPreviewing ? (
                    <>
                    <Button variant="outlined" disabled>
                      {" "}
                      Preview{" "}
                    </Button>
                    </>
                  ) : (
                    <>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">File Format</InputLabel>
                    <Select
                    variant='outlined'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={{
                        width: "100%",
                        marginY: 1,
                        marginX: -2
                      }}
                      label='File Format'
                      onChange={fileFormatSelectChangeHandler}
                    >
                      <MenuItem value={".stl"}>{".stl"}</MenuItem>
                      <MenuItem value={".obj"}>{".obj"}</MenuItem>
                      <MenuItem value={".mtl"}>{".mtl"}</MenuItem>
                      <MenuItem value={".dxf"}>{".dxf"}</MenuItem>
                    </Select>
                    </FormControl>

                    {!selectedFileFormat ? 
                      <>
                      <Button variant="outlined" disabled>
                        Download
                      </Button>
                      </>
                      :
                      <>
                      <Button variant="outlined" onClick={() => {
                        // (async () => {

                        // window.open(isPreviewing + ".stl");

                        // await timeout(300);

                        // window.open(isPreviewing + ".obj");

                        // await timeout(300);

                        // window.open(isPreviewing + ".mtl");

                        // await timeout(300);

                        // window.open(isPreviewing + ".dxf");

                        // // const link = document.createElement('a');
                        // // link.href = isPreviewing + ".dxf";
                        // // document.body.appendChild(link);
                        // // link.click();
                        // // document.body.removeChild(link);

                        // await timeout(200);
                        
                        // })();

                        const link = document.createElement('a');
                        link.href = isPreviewing + selectedFileFormat;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        }}>
                        Download
                      </Button>
                      </>
                      }

                      <br></br>
                      <Button variant="outlined" onClick={() => {
                        handleModalOpen()
                      }}>
                        Large Preview
                      </Button>
                      <Modal
                      open={modalOpen}
                      onClose={handleModalClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={modalStyle}>
                      <StlViewer
                       width={800}
                        height={600}
                        url={`${isPreviewing}.stl`}
                        groundColor='rgb(255, 255, 255)'
                        objectColor='rgb(50, 255, 255)'
                        skyboxColor='rgb(255, 255, 255)'
                        gridLineColor='rgb(0, 0, 0)'
                        lightColor='rgb(255, 255, 255)'
                        volume={setvolume}
                      />
                      <Button onClick={handleModalClose}>Close View</Button>
                      </Box>
                    </Modal>

                      <div  style={{display:'flex',justifyContent:'center',marginTop:'1rem'}}>
                      {
                      !(modalOpen) &&
                      <StlViewer
                      width={300}
                       height={400}
                       url={`${isPreviewing}.stl`}
                       groundColor='rgb(255, 255, 255)'
                       objectColor='rgb(50, 255, 255)'
                       skyboxColor='rgb(255, 255, 255)'
                       gridLineColor='rgb(0, 0, 0)'
                       lightColor='rgb(255, 255, 255)'
                       volume={setvolume}
                     />
                      }
                      </div>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
            </Container>

        </main>
        <footer className="grow flex p-10 font-poppins xl:flex-row sm:flex-col sm:items-center md:gap-20 gap-20">
          <div className="logo-container w-2/6 h-28 flex items-center text-black justify-center xl:border-r-2">
            <div className="logo-div flex flex-col w-2/6 items-center justify-center h-20">
              <h2 className="w-28 pb-1"> Sponsored By </h2>
              <img src={hubcapLogo} style={{width:120,height:120}} />
            </div>
            
          </div>
          <div className="logo-div flex flex-col  items-center justify-center h-20">
              <h2 className="w-24 pb-1"> Created By </h2>
              <img style={{width:120,height:120}} src={vulpesLogo} alt="" />
            </div>
          <div className="logo-div flex flex-col items-start justify-center h-20 pt-4">
              <img src={intLogo} style={{width:180,height:45}} />
            </div>
          <div className="w-4/6 h-28 flex items-start justify-center">
            <p className="md:w-5/6 text-justify h-22 text-sm w-full md:text-base pb-10">
              SMARTEXMOD is a European-funded project to enhance the efficiency
              of design, development and prototyping of smart clothing by
              providing pre-build modules to add into the design software and
              use the assets for rapid prototyping. 
              <br></br><br></br>
              HUBCAP is a digital innovation HUBs and Collaborative Platform for cyber-physical systems
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
